import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo.js"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl, navigate } from "gatsby-plugin-react-intl"
import Header from "../../components/ServicesPage/BuyPage/Header"
import DescriptionComponent from "../../components/ServicesPage/BuyPage/DescriptionComponent"

const BuyPage = () => {
  const intl = useIntl()
  const lang = intl.locale
  const [data, setData] = useState({})
  const isBrowser = typeof window !== "undefined"
  const query = useStaticQuery(graphql`
    query Buy {
      polish: contentfulBuy(node_locale: { eq: "pl-PL" }) {
        field1 {
          raw
        }
        field2 {
          raw
        }
        button1
        button2
        slider1
        slider2
      }

      english: contentfulBuy(node_locale: { eq: "en-US" }) {
        field1 {
          raw
        }
        field2 {
          raw
        }
        button1
        button2
        slider1
        slider2
      }
    }
  `)

  useEffect(() => {
    if (lang === "en") {
      navigate("/services/purchase/")
    }
  }, [lang])

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData(query.english)
      }
      if (intl.locale === "pl") {
        setData(query.polish)
      }
    }
  }, [intl])

  return (
    <Layout>
      <Seo title="Zakup" />
      <Header slider1={data.slider1} slider2={data.slider2} />
      <DescriptionComponent
        field1={data.field1}
        field2={data.field2}
        button1={data.button1}
        button2={data.button2}
      />
    </Layout>
  )
}

export default BuyPage
